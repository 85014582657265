@font-face {
  font-family: "Poppins";
  src: url(../src/assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "Poppins-Light";
  src: url(../src/assets/fonts/Poppins-Light.ttf);
}

@font-face {
  font-family: "Poppins-Bold";
  src: url(../src/assets/fonts/Poppins-Bold.ttf);
}

textarea {
  resize: none !important;
}

.card-image {
  width: 4rem;
  height: 4rem;
}

@media only screen and (max-width: 767px) {
  .card-image {
    width: 3rem;
    height: 3rem;
  }

  .news-table {
    table-layout: fixed;
  }
}