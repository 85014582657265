.carousel-container {
    width: 100%;

    .carousel.carousel-slider {
        .control-arrow.control-prev {
            bottom: 0;
            margin: 0;
            top: unset;
            padding: 0;
            width: 2rem;
            left: unset;
            height: 2rem;
            display: flex;
            right: 2.5rem;
            background: #fff;
            border-radius: 7px;
            align-items: center;
            justify-content: center;

            &:hover {
                background-color: #fff;
            }

            &::before {
                border-right: 8px solid #171c57;
            }
        }

        .control-arrow.control-next {
            bottom: 0;
            margin: 0;
            top: unset;
            padding: 0;
            width: 2rem;
            height: 2rem;
            display: flex;
            background: #fff;
            border-radius: 7px;
            align-items: center;
            justify-content: center;

            &:hover {
                background-color: #fff;
            }

            &::before {
                border-left: 8px solid #171c57;
            }
        }
    }
}

.carousel-container-2 {
    width: 100%;

    .carousel.carousel-slider {
        .control-arrow.control-prev {
            bottom: 0;
            margin: 0;
            top: unset;
            padding: 0;
            width: 2rem;
            left: unset;
            height: 2rem;
            display: flex;
            right: 2.5rem;
            background: #fff;
            border-radius: 7px;
            align-items: center;
            justify-content: center;

            &:hover {
                background-color: #fff;
            }

            &::before {
                border-right: 8px solid #171c57;
            }
        }

        .control-arrow.control-next {
            bottom: 0;
            margin: 0;
            top: unset;
            padding: 0;
            width: 2rem;
            height: 2rem;
            display: flex;
            background: #fff;
            border-radius: 7px;
            align-items: center;
            justify-content: center;

            &:hover {
                background-color: #fff;
            }

            &::before {
                border-left: 8px solid #171c57;
            }
        }
    }
}

.Please.fill.in.the.empty {
    color: #d60404c6;
}

.Message.successfully {
    color: #35e735;
}

.Something.went {
    color: #d60404c6;
}

.no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */

    &::-webkit-scrollbar {
        display: none;
    }
}

textarea.about-us-textarea {
    min-height: 30rem;
}
