.universal-description {
    margin: 10px 0 2rem;
    font-size: 12px;
    font-family: 'Poppins', sans-serif;

    ul,
    ol {
        margin: 0 0 0 1.5rem;
    }
}

.nav-link {

    img {
        opacity: 1;
        transition: opacity 0.5s ease-in-out;
    }

    &:hover {
        img {
            opacity: 0;
        }
    }
}